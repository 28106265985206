export default {
  key: "illeetvilainetourisme",
  canonicalUrl: "www.bretipass.fr",
  datalayerId: "GTM-N7FRS7X",
  customerId: 691,
  icon: "illeetvilainetourisme.ico",
  headerLogo: "illeetvilainetourisme.png",
  footerLogo: "illeetvilainetourisme_monochrome.png",
  axeptioClientId: "",
  axeptioCookiesVersion: "www.bretipass.fr",
  clientUrl: "https://detenteur.bretipass.fr",
  proUrl: "https://partenaire.bretipass.fr",
  sanity: {
    base: {
      projectId: "tlyxth62",
      dataset: "production",
      apiVersion: "2022-12-01",
      useCdn: true,
    },
    preview: {
      useCdn: false,
      withCredentials: true,
      token:
        "sklJdpK0itvD9IjAzDfhb7nlVcq6KDwF7fIbY6jBxclne5zGaJYcYhlmZ8i8GRxrFoROljRkRHgcS1phmepULhDDhZZ5KHXfsq3EuRj8v04JIAy8QFeAUHz92qwTVwslQWMLiiGdIslgwCX1Ip3NtbPVkQ5rK8CDX5qpyWwUX4RUBdjEuUhC",
    },
  },
  dev: {
    clientUrl: "https://detenteur.bretipass.fr",
    proUrl: "https://partenaire.bretipass.fr",
  },
};
